import * as React from 'react';
import classNames from 'classnames';
import styles from './OfferDisclaimer.module.scss';

const OfferDisclaimer: React.FC = () => {
    return (
        <div className={classNames(styles.offerDisclaimer, "kmx-ico-offer-disclaimer", "kmx-typography--body-2")}>
            <div className={classNames(styles.section, "kmx-ico-offer-disclaimer-primary")}>
                <span className={classNames(styles.indicator, "kmx-ico-offer-disclaimer-indicator")}>*</span>
                The offer from CarMax is contingent on your providing accurate information.
                CarMax will conduct a verification of your vehicle and evaluate other vehicle use and history information prior to finalizing the offer.
                Any differences between the information you provide about your vehicle and the vehicle&apos;s actual condition, use, and history may impact the offer you receive from CarMax.
            </div>
            <div>
                <span>
                    Please note that CarMax cannot purchase a leased vehicle from certain companies. For more details, click <a href={`${process.env.SUYC_URL}#faq-lease`} target="_blank" rel="noreferrer">here</a>.
                </span>
            </div>
        </div>
    );
};

export default OfferDisclaimer;
