import * as React from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { formatSeperatedInteger } from '../../utils/format';
// import { emailOffer } from '../../api/submit';
import Swoosh from '../../assets/swoosh.png';
import styles from './Offer.module.scss';
import { isAssociateView, updateSourceSection, withAnalyticsClickTracking } from '../../utils/analytics';
import { testNames, useFeatures } from '../../context/features';
import KbbValuation from './KbbValuation';
import WalletPassImage from '../../assets/wallet_pass.svg';
import { getWalletPassV2 } from '../../api/walletPass';
// import { useCustomerInfo } from '../../context/customerInfo';
import { useClientInfo } from '../../context/clientInfo';
import { useOfferContext } from '../../context/offerContext';
import { isMobile, isIOS } from 'react-device-detect';
import { ORIGIN_PAGES } from '../../constants';
import { setAppointment, goToMyKmx, goToOfferDetails, goToPreQual, goToCarSearch } from '../../utils/redirect';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import ICODialog from '../ICODialog';
import Card from '../Card';
const Button = withAnalyticsClickTracking(KmxButton);

interface IOfferProps {
    className?: string;
    vin: string;
    originPage?: string;
    userId: string;
    storeId?: string;
    onEditVehicleClick: () => void;
}

// const REDEEM_MY_OFFER_ID = 'offer-cta-redeem-offer';
// const SHOP_FOR_MY_NEXT_CAR_ID = 'offer-cta-shop-for-next-car';
// const SCHEDULE_MY_APPOINTMENT_ID = 'offer-cta-schedule-appt';
const OFFER_PAGE_ID = 'Offer Page';
// const SAVE_MY_OFFER_FOR_LATER_ID = 'offer-cta-save-my-offer-for-later';

const Offer: React.FC<IOfferProps> = props => {
    // const { customerInfo } = useCustomerInfo();
    const appInsights = useAppInsightsContext();
    const [continueModalOpen, setContinueModalOpen] = useState(false);
    const { offer, kbb } = useOfferContext();
    const { clientInfo } = useClientInfo();
    const { isFeatureEnabled } = useFeatures();

    useEffect(() => {
        appInsights.trackEvent({
            name: 'Offer page - viewed',
            properties: {
                quoteId: offer.code,
                vin: props.vin
            }
        });
        // Update source section when the offer page loads
        updateSourceSection('Offer Page');
    }, []);

    // useEffect(() => {
    //     if (customerInfo.email !== '') {
    //         emailOffer(
    //             customerInfo.email,
    //             offer?.code,
    //             getAppointmentFlow(),
    //             offer?.expiresDisplayDate
    //         );
    //     }
    // }, []);

    const ctaValues = {
        headline: `We're ready to buy your car`,
        description: `Bring your car and offer to any CarMax store.\nWe'll confirm your car's condition and pay you on the spot.`,
        buttons: [
            { header: 'Continue', content: '' },
            ...(props.userId ? [] : [{ header: 'Save this offer', content: '' }]),
        ],
    };

    if (!offer) {
        return null;
    }

    // const onSelectedCardContinue = (_: React.MouseEvent, selectedCardId: string) => {
    //     if (selectedCardId === REDEEM_MY_OFFER_ID || selectedCardId === SCHEDULE_MY_APPOINTMENT_ID) {
    //         setAppointment();
    //     } else if (selectedCardId === SHOP_FOR_MY_NEXT_CAR_ID) {
    //         window.open(`${process.env.LEADS_URL}#budget-calculator-section`, '_blank');
    //     } else if (selectedCardId === SAVE_MY_OFFER_FOR_LATER_ID) {
    //         goToMyKmx();
    //     }
    // };

    const continueClick = () => {
        if (isFeatureEnabled(testNames.TRADE_IN_CTA)) {
            setContinueModalOpen(true);
        }
        else {
            setAppointment(props.originPage, clientInfo, offer);
        }
    };

    const getCtaButtons = () => {
        if (kbb) {
            return null;
        }

        const continueButton = (
            <Button
                id="ico-set-my-appointment"
                onClick={continueClick}
                level="primary"
            >
                Continue
            </Button>
        );

        if (props.originPage === ORIGIN_PAGES.inStore) {
            return continueButton;
        }

        return continueButton;
    };

    const getHeadlineText = () => {
        if (props.originPage === ORIGIN_PAGES.inStore) {
            return 'Check-in to Continue';
        }

        return ctaValues.headline;
    };

    const getDescriptionText = () => {
        if (props.originPage === ORIGIN_PAGES.inStore) {
            return `Check-in to have your offer verified or if you have any questions.\nWe'll confirm your car's conditions and pay you on the spot.`;
        }

        return ctaValues.description;
    };

    const continueDialog = (
        <ICODialog closeDialog={() => setContinueModalOpen(false)} showDefaultCloseButton={false}>
            <div>
                <h3 className='kmx-typography--display-4'>What do you want to do next?</h3>
                <Card id='next-steps-schedule-appt' header='Schedule my appointment' content="Bring your car and offer to any CarMax store. We'll confirm your car's condition and pay you on the spot." onClick={() => setAppointment(props.originPage, clientInfo, offer)} />
                <Card id='next-steps-prequal' header='Get pre-qualified' content='Get financing results without impacting your credit and see personalized terms on our cars.' onClick={goToPreQual} />
                <Card id='next-steps-shop' header='Shop for my next car' content='Now that you know what you will recieve for your trade-in, use it to shop for another vehicle.' onClick={goToCarSearch} />
            </div>
        </ICODialog>
    );

    return (
        <div data-qa="offer-page" id={OFFER_PAGE_ID}>
            {continueModalOpen && continueDialog}
            <div
                className={classNames('kmx-ico-offer', styles.offerContainer, props.className)}
            >
                <div className={classNames('kmx-ico-offer-offerinfo', styles.offerInfo)}>
                    <h3 className="kmx-typography--display-4">Your Offer</h3>
                    <h1 data-qa="offer-amount" className="kmx-typography--display-6">
                        ${formatSeperatedInteger(offer.valuation)}
                    </h1>
                    <img className={styles.swoosh} src={process.env.BASE_URL + Swoosh} />
                    <p className={classNames(styles.offerCode, 'kmx-typography--display-3')}>
                        Offer code: {offer.code}
                    </p>
                    <p className={classNames(styles.expiration, 'kmx-typography--body-1')}>
                        Valid through {offer.expiresDisplayDate}
                    </p>
                    <div className={styles.nextActionsContainer}>
                        {isFeatureEnabled(testNames.WALLET_PASS) && isIOS && isMobile && (
                            <div id="wallet-pass">
                                <button id="wallet-pass" className={styles.walletPassButton} onClick={() => getWalletPassV2(offer.code)}>
                                    <WalletPassImage className={styles.walletPass} />
                                    wallet-pass
                                </button>
                            </div>
                        )}
                        {kbb &&
                            <Button
                                level="primary"
                                className={styles.offerCta}
                                onClick={continueClick}
                            >
                                Continue
                            </Button>
                        }
                    </div>
                </div>
                {offer?.metaData?.offerDisclaimer && (
                    <div className={styles.offerDisclaimer}>
                        <p className={classNames('kmx-typography--body-2', styles.disclaimerText)}>
                            {offer.metaData.offerDisclaimer}
                        </p>
                    </div>
                )}
                {kbb && <KbbValuation />}
                {!isAssociateView() && (
                    <>
                        <div
                            id="kmx-ico-offer-appointment"
                            className={classNames('kmx-ico-offer-appointment', styles.appointmentContainer)}
                        >
                            <h3 className={classNames('kmx-typography--display-3', styles.headline)}>
                                {getHeadlineText()}
                            </h3>
                            <p className={classNames('kmx-typography--body-2', styles.description)}>
                                {getDescriptionText()}
                            </p>
                            {getCtaButtons()}
                            {!props.userId && (
                                <Button
                                    id="ico-save-offer"
                                    onClick={() => goToMyKmx(offer)}
                                    level={(isFeatureEnabled(testNames.EDIT_VEHICLE_A) || isFeatureEnabled(testNames.EDIT_VEHICLE_C)) ? "secondary" : "tertiary"}
                                    className={styles.saveOffer}
                                >
                                    {'Save this offer'}
                                </Button>
                            )}
                            {isFeatureEnabled(testNames.EDIT_VEHICLE_A) && (
                                <div id="edit-vehicle">
                                    <Button
                                        onClick={props.onEditVehicleClick}
                                        level="tertiary"
                                        className={styles.editVehicle}
                                    >
                                        Edit car details
                                    </Button>
                                </div>
                            )}
                            {isFeatureEnabled(testNames.EDIT_VEHICLE_C) && <Button onClick={() => goToOfferDetails(offer, props.originPage)} level="tertiary" className={styles.viewOffer}>
                                View offer details
                            </Button>}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Offer;
