import * as React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { TextField } from '@kmx/legos-react-text-field';
import { IDynamicAnswer, IDynamicConditionAnswer, IDynamicConditionQuestion } from '../../../types/IConditionQuestion';
import { formatSeperatedInteger } from '../../../utils/format';
import ConditionQuestionsDynamic from '../ConditionQuestionsDynamic';
import {
    getEmailAddressValidationError,
    countConditionQuestionErrors,
    getMileageValidationError,
} from '../../../utils/validation';
import KbbConditionDialog from '../../KbbConditionDialog';
import { useFeatures, testNames } from '../../../context/features';
import { useCustomerInfo } from '../../../context/customerInfo';
import CardQuestion from './CardQuestion';
import { FORM_FIELD_NAMES } from '../../../constants';
import MileageInput from './MileageInput';
import { IQuote } from '../../../types/IQuote';
import { useVehicleInfo } from '../../../context/vehicleInfo';
import { Checkbox } from '@kmx/legos-react-checkbox';
import { useFormContext } from '../../../context/formContext';
import ValidatedArea from '../../ValidatedArea';
import IcoRadio from '../../IcoRadio/IcoRadio';

import styles from './ConditionsBody.module.scss';

interface IConditionsBodyProps {
    onCompleteChange?(isComplete: boolean): void; // Injected by the AccordionStepper
    allValidationTriggered: boolean;
    readonly: boolean;
    includeKbbConditionQuestion: boolean;
    submitIcoOnEmailEnter?: () => void;
    previousOffer: IQuote;
}

const ConditionsBody: React.FC<IConditionsBodyProps> = ({ onCompleteChange, ...props }) => {
    const { customerInfo, setCustomerInfo } = useCustomerInfo();
    const { vehicleConditionInfo, setVehicleConditionInfo, vehicleInfo } = useVehicleInfo();
    const [mileage, setMileage] = useState(formatSeperatedInteger(vehicleConditionInfo.mileage));
    const [email, setEmail] = useState(customerInfo.email);
    const [showKbbDialog, setShowKbbDialog] = useState(false);
    const [emailWasTouched, setEmailWasTouched] = useState(false);
    const [mileageWasTouched, setMileageWasTouched] = useState(false);
    const { isFeatureEnabled } = useFeatures();
    const { formMetadata, setFormMetadata } = useFormContext();

    const marketValueEmailOptInAEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_A);
    const marketValueEmailOptInBEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_B);
    const marketValueEmailOptInCEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_C);
    const marketValueEmailOptInDEnabled = isFeatureEnabled(testNames.MARKET_VALUE_EMAIL_OPT_IN_D);

    const marketValueEmailOptInHelperText = useMemo(() => {
        if (marketValueEmailOptInAEnabled || marketValueEmailOptInBEnabled) {
            return 'Send me regular updates on the value of my car';
        } else if (marketValueEmailOptInCEnabled || marketValueEmailOptInDEnabled) {
            return `Send me ${vehicleInfo.profile.year} ${vehicleInfo.profile.make} ${vehicleInfo.profile.model} market change updates`;
        }
        return '';
    }, [
        marketValueEmailOptInAEnabled,
        marketValueEmailOptInBEnabled,
        marketValueEmailOptInCEnabled,
        marketValueEmailOptInDEnabled,
        vehicleInfo,
    ]);

    useEffect(() => {
        setCustomerInfo(prev => ({ ...prev, email }));
    }, [email]);

    useEffect(() => {
        updatedAnswers(vehicleConditionInfo.conditionAnswers);
    }, [mileage, email, props.allValidationTriggered]);

    const mileageError = useMemo(() => getMileageValidationError(mileage), [mileage]);

    const getErrorCount = useCallback((): number => {
        let count = 0;
        if (isFeatureEnabled(testNames.CONDITION_QUESTION_EMAIL)) {
            count += getEmailAddressValidationError(email) ? 1 : 0;
        }

        count += mileageError ? 1 : 0;
        return count;
    }, [email, mileageError]);

    const getValidationErrorCount = useCallback(
        (answers: IDynamicConditionAnswer[]) => {
            let count = getErrorCount();
            count += countConditionQuestionErrors(vehicleConditionInfo.conditionQuestions, answers);
            return count;
        },
        [getErrorCount, vehicleConditionInfo.conditionQuestions]
    );

    const updatedAnswers = useCallback(
        (answers: IDynamicConditionAnswer[]): void => {
            const errorCount = getValidationErrorCount(answers);
            const isComplete = errorCount === 0;
            onCompleteChange(isComplete);
            setVehicleConditionInfo(prev => ({
                ...prev,
                conditionAnswers: answers,
                isComplete,
                mileage,
                errorCount,
            }));
        },
        [mileage, getValidationErrorCount, setVehicleConditionInfo, onCompleteChange]
    );

    const closeKbbDialog = () => setShowKbbDialog(false);

    const validatedEmailOnEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && getEmailAddressValidationError(email) === null) {
            props.submitIcoOnEmailEnter();
        }
    };

    const emailValidationWasTriggered = emailWasTouched || props.allValidationTriggered;

    const toggleSellingOrTradingAnswer = (question: IDynamicConditionQuestion, answerId: number) => {
        setCustomerInfo(prev =>
            ({ ...prev, sellingOrTrading: answerId.toString() }));
    };

    const renderSingleSelectQuestion = (id: string, question: IDynamicConditionQuestion) => {
        return <fieldset id={id} className="kmx-flex-wrapper">
            <ValidatedArea
                overlayIcon={false}
                invalid={false}
                validationMessage="Please make a selection below"
            >
                <legend>{question.description}</legend>
            </ValidatedArea>
            {question.subDescription && <p className="kmx-typography--fine-print">{question.subDescription}</p>}
            <div>{
                question.answers.map((answer: IDynamicAnswer) => {
                    return (
                        <IcoRadio
                            key={answer.id}
                            id={`${question.id.toString()}-${answer.id.toString()}`}
                            name={question.id.toString()}
                            value={answer.id.toString()}
                            selected={answer.id.toString() === customerInfo.sellingOrTrading}
                            onChange={() => {
                                toggleSellingOrTradingAnswer(question, answer.id);
                            }}
                            label={answer.description}
                            disabled={false}
                        />
                    );
                })
            }</div>
        </fieldset>;
    };

    const sellingOrTradingQuestion = () => {
        if (isFeatureEnabled(testNames.SELLING_OR_TRADING)) {
            return <div>
                {renderSingleSelectQuestion(testNames.SELLING_OR_TRADING, {
                    displayOrder: 99999,
                    answerType: 'single',
                    id: 99999,
                    description: 'Selling or trading in?',
                    answers: [
                        {
                            id: 0,
                            description: 'Selling only'
                        },
                        {
                            id: 1,
                            description: 'Trading in'
                        },
                        {
                            id: 2,
                            description: 'Not sure'
                        }
                    ]
                })}
            </div>;
        }
        else if (isFeatureEnabled(testNames.SELLING_OR_TRADING_DISCLAIMER)) {
            return <div>
                {renderSingleSelectQuestion(testNames.SELLING_OR_TRADING_DISCLAIMER, {
                    displayOrder: 99999,
                    answerType: 'single',
                    id: 99999,
                    description: 'Selling or trading in?',
                    subDescription: "This won't impact your offer.",
                    answers: [
                        {
                            id: 0,
                            description: 'Selling only'
                        },
                        {
                            id: 1,
                            description: 'Trading in'
                        },
                        {
                            id: 2,
                            description: 'Not sure'
                        }
                    ]
                })}
            </div>;
        }
        else if (isFeatureEnabled(testNames.SELLING_OR_TRADING_INTEREST)) {
            return <div>
                {renderSingleSelectQuestion(testNames.SELLING_OR_TRADING_INTEREST, {
                    displayOrder: 99999,
                    answerType: 'single',
                    id: 99999,
                    description: 'Which are you most interested in doing?',
                    answers: [
                        {
                            id: 0,
                            description: 'Selling my car'
                        },
                        {
                            id: 1,
                            description: 'Trading in my car'
                        }
                    ]
                })}
            </div>;
        }
        else if (isFeatureEnabled(testNames.SELLING_OR_TRADING_INTEREST_DISCLAIMER)) {
            return <div>
                {renderSingleSelectQuestion(testNames.SELLING_OR_TRADING_INTEREST_DISCLAIMER, {
                    displayOrder: 99999,
                    answerType: 'single',
                    id: 99999,
                    description: 'Which are you most interested in doing?',
                    subDescription: "This won't impact your offer.",
                    answers: [
                        {
                            id: 0,
                            description: 'Selling my car'
                        },
                        {
                            id: 1,
                            description: 'Trading in my car'
                        }
                    ]
                })}
            </div>;
        }
        else {
            return null;
        }
    };

    return (
        <div id="icoConditionsBody" className={styles.container}>
            {showKbbDialog && <KbbConditionDialog closeKbbDialog={closeKbbDialog} />}
            {props.includeKbbConditionQuestion &&
                <CardQuestion
                    readOnly={props.readonly}
                    conditionValidationTriggered={props.includeKbbConditionQuestion}
                    includeKbbConditionQuestion={props.includeKbbConditionQuestion}
                    setShowKbbDialog={setShowKbbDialog}
                    presetAnswer={props.previousOffer?.metaData?.selectedKbbCondition}
                />
            }
            <MileageInput
                readOnly={props.readonly}
                value={mileage}
                setValue={setMileage}
                wasTouched={mileageWasTouched || props.allValidationTriggered}
                setWasTouched={setMileageWasTouched}
            />

            <h3 className="kmx-typography--headline-2">Additional Info</h3>
            <ConditionQuestionsDynamic
                allValidationTriggered={props.allValidationTriggered}
                readonly={props.readonly}
                updatedAnswers={updatedAnswers}
            />

            {sellingOrTradingQuestion()}

            {isFeatureEnabled(testNames.CONDITION_QUESTION_EMAIL) && (
                <div>
                    <h3 className="kmx-typography--headline-2">Offer delivery</h3>
                    <h3 className="kmx-typography--body-2">
                        Please provide an email address and we&apos;ll send a copy of your offer.
                    </h3>
                    <fieldset>
                        <TextField
                            label="Preferred email"
                            name={FORM_FIELD_NAMES.conditionsBody.email}
                            value={email}
                            type="email"
                            autoComplete="email"
                            onChange={e => setEmail(e.target.value.replace(/\s/, ''))}
                            disabled={props.readonly}
                            onBlur={() => setEmailWasTouched(true)}
                            validationStatus={
                                !emailValidationWasTriggered
                                    ? null
                                    : getEmailAddressValidationError(email)
                                        ? 'invalid'
                                        : 'valid'
                            }
                            helperText={emailValidationWasTriggered ? getEmailAddressValidationError(email) : null}
                            maxLength={50}
                            inputMode={'email'}
                            inputProps={{ onKeyPress: (event: KeyboardEvent) => validatedEmailOnEnter(event) }}
                            inputClassName="fs-mask"
                        />
                        {(marketValueEmailOptInAEnabled ||
                            marketValueEmailOptInBEnabled ||
                            marketValueEmailOptInCEnabled ||
                            marketValueEmailOptInDEnabled) && (
                                <Checkbox
                                    checked={formMetadata.marketValueEmailOptIn}
                                    onChange={() =>
                                        setFormMetadata(prev => ({
                                            ...prev,
                                            marketValueEmailOptIn: !prev.marketValueEmailOptIn,
                                        }))
                                    }
                                    label="Track your Value"
                                    helperText={marketValueEmailOptInHelperText}
                                />
                            )}
                    </fieldset>
                </div>
            )}
        </div>
    );
};

export default ConditionsBody;
