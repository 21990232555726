import * as React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import Tab from '../TabBar/Tab';
import TabBar from '../TabBar/TabBar';
import TabPanel from '../TabBar/TabPanel';
import ValidatedArea from '../ValidatedArea';
import { TextField } from '@kmx/legos-react-text-field';
import { Select } from '@kmx/legos-react-select';
import usaStates from '../../utils/usaStates';
import classNames from 'classnames';
import { formatVin } from '../../utils/format';
import { isValidVin } from '../../utils/validation';
import styles from './LicenseOrVin.module.scss';
import { Button as KmxButton } from '@kmx/legos-react-button';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { useFormContext } from '../../context/formContext';
import useFormField from '../../hooks/useFormField';
import { withAnalyticsClickTracking } from '../../utils/analytics';
import { testNames, useFeatures } from '../../context/features';
const Button = withAnalyticsClickTracking(KmxButton);

enum tabState {
    PLATE,
    VIN,
}

interface ILicenseOrVinProps {
    readOnly: boolean;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

const SELECT_HELPER_TEXT_ID = 'ico-vehicle-state-helper-text';
const LICENSE_PLATE_TAB_ID = 'LicensePlate';
const STATE_SELECT_ID = 'ico-vehicle-state';
const VIN_TAB_ID = 'VIN';

const LicenseOrVin: React.FC<ILicenseOrVinProps> = ({ readOnly, onSubmit }) => {
    const { vehicleInfo, setVehicleInfo } = useVehicleInfo();
    const { isFeatureEnabled } = useFeatures();
    const {
        plateVinValid,
        setPlateDecodeError,
        plateDecodeError,
        setPlateVinValid,
        setFormMetadata,
        zipDecodeError,
        setZipDecodeError,
    } = useFormContext();
    const vin = useFormField(vehicleInfo.vin);
    const state = useFormField(vehicleInfo.state);
    const plate = useFormField(vehicleInfo.plate);
    const zipCode = useFormField(vehicleInfo.zipcode);
    const [activeTab, setActiveTab] = useState(tabState.PLATE);
    const [hideZipCode] = useState(vehicleInfo.zipcode?.length === 5);
    const isPlateTabActive = useMemo(() => activeTab === tabState.PLATE, [activeTab]);
    const isGetMyOfferButtonEnhancementTestEnabled = isFeatureEnabled(testNames.GET_MY_OFFER_ENHANCEMENT_B);
    const isGetMyOfferTextEnhancementTestEnabled =
        isFeatureEnabled(testNames.GET_MY_OFFER_ENHANCEMENT_A) ||
        isFeatureEnabled(testNames.GET_MY_OFFER_ENHANCEMENT_B);

    useEffect(() => {
        if (isFeatureEnabled(testNames.DEFAULT_VIN)) {
            setActiveTab(tabState.VIN);
        }
    }, [isFeatureEnabled]);

    const zipValidationError = useMemo((): string => {
        if (zipCode.value == null || zipCode.value.length <= 0) return 'ZIP code must be entered';
        else if (zipCode.value.length != 5) return 'ZIP code must be 5 digits';
        else if (zipDecodeError) return zipDecodeError;
        else return null;
    }, [zipCode.value, zipDecodeError]);

    const vinValidationError = useMemo((): string => {
        if (vin.value == null || vin.value.length <= 0 || vin.value === '') return 'VIN must be entered';
        else if (vin.value.length != 17) return 'VIN must be a 17 character Vehicle Identification Number';
        else if (!isValidVin(vin.value))
            return 'VIN must be a valid Vehicle Identification Number. Please check that you have entered the VIN correctly.';
        else return null;
    }, [vin.value]);

    const plateValidationError = useMemo((): string => {
        if (plateDecodeError)
            return 'We cannot find that license plate, please try again';
        if (plate.value == null || plate.value.replace(/[^0-9a-zA-Z]*/, '').length <= 0)
            // LP must be 1 or more characters, not including any spaces, dashes, etc.
            return 'License Plate must be entered';
        else return null;
    }, [plate.value, plateDecodeError]);

    const stateValidationError = useMemo((): boolean => {
        if (state.value == null || state.value.length <= 0 || state.value === '') return true;
        else return false;
    }, [state.value]);

    const isGetStartedButtonDisabled = useMemo(() => {
        if (!isPlateTabActive && !vinValidationError && !zipValidationError) {
            return false;
        } else if (!isPlateTabActive && (vinValidationError || zipValidationError)) {
            return true;
        } else if (
            isPlateTabActive &&
            !plateDecodeError &&
            !stateValidationError &&
            !plateValidationError &&
            !zipValidationError &&
            !plateDecodeError
        ) {
            return false;
        } else if (
            isPlateTabActive &&
            (plateDecodeError || stateValidationError || plateValidationError || zipValidationError)
        ) {
            return true;
        }

        return readOnly || !plateVinValid || plateDecodeError;
    }, [
        isPlateTabActive,
        plateVinValid,
        readOnly,
        zipValidationError,
        plateValidationError,
        vinValidationError,
        stateValidationError,
        plateDecodeError,
    ]);

    useEffect(() => {
        setFormMetadata(prev => ({ ...prev, startingMethod: isPlateTabActive ? 'LP' : 'VIN' }));
    }, [isPlateTabActive, setFormMetadata]);

    useEffect(() => {
        if (
            !zipValidationError &&
            (!vinValidationError || (state != null && state.value !== '' && !plateValidationError))
        ) {
            setPlateVinValid(true);
            setVehicleInfo(prev => ({
                ...prev,
                isComplete: true,
                zipcode: zipCode.value,
                vin: vin.value,
                plate: plate.value,
                state: state.value,
            }));
        } else {
            setPlateVinValid(false);
        }
    }, [
        vin,
        state,
        plate,
        zipCode,
        zipValidationError,
        plateValidationError,
        vinValidationError,
        setPlateVinValid,
        setVehicleInfo,
    ]);

    const plateOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            plate.setValue(event.target.value.replace(/[^0-9a-zA-Z \\-]/, ''));
            plate.setWasTouched(true);
            setPlateDecodeError(false);
        },
        [plate, setPlateDecodeError]
    );

    const plateStateOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            state.setValue(event.target.value);
            setPlateDecodeError(false);
        },
        [state, setPlateDecodeError]
    );

    const onZipcodeChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            zipCode.setValue(e.target.value.replace(/[^0-9]/, ''));
            setZipDecodeError('');
        },
        [zipCode, setZipDecodeError]
    );

    const zipField = useMemo(() => {
        if (hideZipCode && !zipDecodeError) {
            return null;
        }

        const validationStatus =
            zipCode.wasTouched || zipDecodeError ? (zipValidationError ? 'invalid' : 'valid') : null;

        return (
            <div>
                <TextField
                    className={classNames(styles.inputField, { [styles.top]: !isPlateTabActive })}
                    onChange={onZipcodeChange}
                    label={isGetMyOfferTextEnhancementTestEnabled ? 'Zip code' : "What's your zip code?"}
                    id={isPlateTabActive ? 'ico-vehicle-zipCode' : 'ico-vehicle-vin-zipCode'}
                    name="zipCode"
                    value={zipCode.value}
                    disabled={readOnly}
                    onBlur={() => zipCode.setWasTouched(true)}
                    validationStatus={validationStatus}
                    helperText={zipValidationError || 'ZIP code must be 5 digits'}
                    maxLength={5}
                    inputMode="numeric"
                    inputProps={{ 'aria-invalid': zipValidationError ? 'true' : 'false' }}
                    required
                />
            </div>
        );
    }, [isPlateTabActive, hideZipCode, readOnly, zipCode, zipValidationError, onZipcodeChange, zipDecodeError, isGetMyOfferTextEnhancementTestEnabled]);

    const setTab = useCallback(
        (tab: tabState) => {
            setActiveTab(tab);
            setFormMetadata(prev => ({ ...prev, startingMethod: tab === tabState.PLATE ? 'LP' : 'VIN' }));
        },
        [setFormMetadata]
    );

    useEffect(() => {
        // This fixes a bug that LEGOs is causing for Safari desktop browsers.
        // See this card for more info: https://carmax.leankit.com/card/1825618666
        const select = document.querySelector(`#select-${STATE_SELECT_ID}`);
        const onBlur = (event: Event) => {
            state.setValue((event.target as HTMLInputElement)?.value);
            state.setWasTouched(true);
        };

        select?.addEventListener('blur', onBlur, false);

        return () => {
            select?.removeEventListener('blur', onBlur, false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            // Reuse buttom disabled validation to return early if invalid
            if (isGetMyOfferButtonEnhancementTestEnabled && isGetStartedButtonDisabled) return;
            onSubmit(event);
        },
        [isGetMyOfferButtonEnhancementTestEnabled, isGetStartedButtonDisabled, onSubmit]
    );

    const subtitle = <p className={styles.subtitle}>{`Most cars qualify, some we'll ask to see in person.`}</p>;

    return (
        <form
            onSubmit={handleSubmit}
            className={classNames(styles.icoForm, {
                [styles.getMyOfferTextEnhancement]: isGetMyOfferTextEnhancementTestEnabled,
            })}
        >
            <h2 className={classNames('kmx-typography--display-4', styles.title)}>Get a real offer in minutes</h2>
            {!isGetMyOfferTextEnhancementTestEnabled && subtitle}
            <TabBar className={styles.tabBar} id="ICO: Vehicle Profile">
                <Tab id={LICENSE_PLATE_TAB_ID} onClick={() => setTab(tabState.PLATE)} active={isPlateTabActive}>
                    LICENSE PLATE
                </Tab>
                <Tab id={VIN_TAB_ID} onClick={() => setTab(tabState.VIN)} active={!isPlateTabActive}>
                    VIN
                </Tab>
            </TabBar>
            <TabPanel active={isPlateTabActive} id={LICENSE_PLATE_TAB_ID}>
                <TextField
                    className={classNames(styles.inputField, styles.top, {
                        [styles.inputInvalid]: plateDecodeError,
                    })}
                    onChange={e => plateOnChange(e)}
                    label="License plate number"
                    id="ico-vehicle-licensePlate"
                    name="licensePlate"
                    value={plate.value}
                    disabled={readOnly}
                    onBlur={() => plate.setWasTouched(true)}
                    validationStatus={
                        !plate.wasTouched ? null : plateValidationError || plateDecodeError ? 'invalid' : 'valid'
                    }
                    helperText={
                        plateDecodeError && plateValidationError
                            ? plateValidationError
                            : plateValidationError || 'License Plate must be entered'
                    }
                    helperTextIsPersistent={plateDecodeError && plateValidationError ? true : false}
                    inputProps={{ 'aria-invalid': plateDecodeError || plateValidationError ? 'true' : 'false' }}
                    maxLength={10}
                    required
                />
                <div className={classNames({ [styles.stateZipWrapper]: isGetMyOfferTextEnhancementTestEnabled })}>
                    <ValidatedArea
                        className={classNames(styles.inputField, styles.validatedArea, {
                            [styles.validatedError]: !state?.value && state.wasTouched,
                        })}
                        overlayIcon={true}
                        invalid={(!state?.value && state.wasTouched) || plateDecodeError}
                        validationMessage={plateDecodeError ? 'State' : 'Please make a selection above'}
                        helperTextId={SELECT_HELPER_TEXT_ID}
                    >
                        <Select
                            className={classNames({ [styles.inputInvalid]: plateDecodeError })}
                            inputProps={{ 'aria-describedby': SELECT_HELPER_TEXT_ID }}
                            options={usaStates}
                            value={state.value}
                            disabled={readOnly}
                            onChange={plateStateOnChange}
                            label={isGetMyOfferTextEnhancementTestEnabled ? 'State' : "Where's your car registered?"}
                            id={STATE_SELECT_ID}
                            name="state"
                            required
                        />
                    </ValidatedArea>
                    {zipField}
                </div>
            </TabPanel>
            <TabPanel active={!isPlateTabActive} id={VIN_TAB_ID}>
                <TextField
                    className={classNames(styles.inputField, styles.top)}
                    onChange={e => vin.setValue(formatVin(e.target.value))}
                    label="VIN"
                    id="ico-vehicle-vin"
                    name="vin"
                    value={vin.value}
                    disabled={readOnly}
                    onBlur={() => vin.setWasTouched(true)}
                    validationStatus={!vin.wasTouched ? null : vinValidationError ? 'invalid' : 'valid'}
                    helperText={vinValidationError || 'VIN must be a 17 character Vehicle Identification Number'}
                    inputProps={{ 'aria-invalid': vinValidationError ? 'true' : 'false' }}
                    maxLength={17}
                    required
                />
                {zipField}
            </TabPanel>
            <Button
                id="ico-getstarted-button"
                level="primary"
                className={classNames(styles.getStartedButton, {
                    disabled: !isGetMyOfferButtonEnhancementTestEnabled && isGetStartedButtonDisabled,
                })}
                type="submit"
                disabled={!isGetMyOfferButtonEnhancementTestEnabled && isGetStartedButtonDisabled}
                onClick={() => true}
            >
                {'GET MY OFFER'}
            </Button>
            {isGetMyOfferTextEnhancementTestEnabled && subtitle}
        </form>
    );
};

export default LicenseOrVin;
